import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tranfer',
  templateUrl: './tranfer.component.html',
  styleUrls: ['./tranfer.component.css']
})
export class TranferComponent implements OnInit {
  details = {
    title: '',
    content: ''
  };
  id = ''
  wallets_arr = []
  wallet = 0.0;
  user;
  MyIp;

  constructor(
    private _app: AppOperationService, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    // this.route.params.subscribe((params: Params) => {
      // this.id = params['id'] == undefined ? null : params['id'];
      this.getuser()
      this.spinner.show()
      this._app.getSingleStaticPagenData("63dd4013cc9737fa8d148b40").subscribe(x => {
        this.details = x[appConstant.ITEMS] as any;
        // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
        let plainText2 = x['content'].replace(/&quot;/g, '"')
        this.details.title = x['title']
        this.details.content = plainText2
        this.spinner.hide()
      });
    // });
  }

  getUserWallet(id){
    this._app.getUserWallet(id).subscribe(x => {
      this.wallets_arr = x[appConstant.ITEMS] as any[];
    });

  }

  getuser() {
    this._app.getUser().subscribe(x => {
      this.wallet = x[appConstant.ITEMS]["wallet"] as any;
      this.user = x[appConstant.ITEMS];
      this.getUserWallet(this.user._id)
    });

    this.http.get<{ ip: string }>("https://jsonip.com").subscribe(data => {
      this.MyIp = data["ip"];
    });
  }

  updateRefrence(value){
    if(value == '' || value == undefined || value == null){
      this.toastr.error("الرجاء ادخل الرقم المرجعي","خطأ");
      return;
    }

    let body = { ref_no: value }
    this._app.updateRefNo(body).subscribe(x=>{
    let msg = x[appConstant.MESSAGE]
      if(x[appConstant.STATUS]){
        this.getuser();
        this.toastr.success(msg,"نجاح");
        this.router.navigate(["/profile",{ outlets: { profile: [ 'wallet' ] }}]);
      }else{
        this.toastr.error(msg,"خطأ");
      }
    })
  }


  makeid() {
    var text = "";
    var possible = "0123456789";

    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
}
