import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.css']
})
export class RateComponent implements OnInit {
  @ViewChild("btnRefresh") btnRefresh: ElementRef;
  @Input() order: any
  rating = 1;
  content = {
    rate: 1,
    comment: ''
  }
  myId=""
  constructor(private _app: AppOperationService, private toastr: ToastrService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private router: Router) {
  this.getUserInfo()
  }
  ngOnInit() {
  }


  getUserInfo(){
    this._app.getUser().subscribe(item => {
      this.myId = item[appConstant.ITEMS]._id as any;
    });
  }


  rate(btn) {
    this.content.rate = this.rating;
    this._app.addRate(this.content, this.order._id).subscribe(x => {
      let status = x['status'] as boolean
      let msg = x[appConstant.MESSAGE] as string
      if (status == false) {
        this.toastr.error(msg, 'خطأ')
      } else {
        this.toastr.success(msg, 'نجاح')
      }

      this.content = {
        rate: 1,
        comment: ''
      }
      this.rating = 1;
      btn.click()
      setTimeout(() => {
        this.btnRefresh.nativeElement.click()
      }, 3000);
      //this.router.navigate(['/profile', { outlets: { profile: ['opperation'] } }])
    })
  }

  onClick(rating: number): void {
    this.rating = rating;
  }
}
