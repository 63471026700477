import { Component, OnInit } from '@angular/core';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  id = ''
  news = []
  _news;
  constructor(private _app: AppOperationService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'] == undefined ? null : params['id'];
      this.spinner.show()
      this._app.getsingleNews(this.id).subscribe(x => {
        this._news = x[appConstant.ITEMS] as any;
        let plainText2 = x['content'].replace(/&quot;/g, '"')
        this._news.title = x['title']
        this._news.image = x['image']
        this._news.details = plainText2
        this.spinner.hide()
      });

      this._app.getNews(3).subscribe(x => {
        this.news = x[appConstant.ITEMS] as any[]
      })
    });
  }
}
