import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-operation-modal',
  templateUrl: './operation-modal.component.html',
  styleUrls: ['./operation-modal.component.css']
})
export class OperationModalComponent implements OnInit {

  @Input() order:any
  constructor() { }

  ngOnInit() {
  }

}
