import { Component, OnInit } from '@angular/core';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  user = {
    email: ''
  }
  returnMsg = ''
  constructor(private _app: AppOperationService, private toastr: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit() {

  }

  Save(content) {
    this.returnMsg = ''

    this._app.resetPasswordUser(this.user).subscribe(x => {
      let status = x['status'] as Boolean
      this.returnMsg = x[appConstant.MESSAGE] as string
      this.user = {
        email: ''
      }

      if (status) {
        // redirect 
      }

      if (status == false) {
        this.toastr.error(this.returnMsg, 'خطأ')
      } else {
        this.toastr.success(this.returnMsg, 'نجاح')
      }
    })
  }

}
