import { Component, OnInit } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { appConstant } from "src/app/services/_constant/appConstant";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-profile-info",
  templateUrl: "./profile-info.component.html",
  styleUrls: ["./profile-info.component.css"]
})
export class ProfileInfoComponent implements OnInit {
  user={
    full_name:"",
    email:"",
    phone_number:"",
    iban:""
  };
  user2 = {
    password: "",
    password2: ""
  };
  returnMsg = "";
  returnMsg2 = "";

  constructor(
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this._app.getUser().subscribe(x => {
      this.user = x[appConstant.ITEMS] as any;
    });
  }

  Save(user) {
    this.returnMsg = "";
    this._app.updateprofile(this.user).subscribe(x => {
      this.returnMsg = x[appConstant.MESSAGE] as string;
      let status = x["status"] as boolean;
      if (status == false) {
        this.toastr.error(this.returnMsg, "خطأ");
      } else {
        this.toastr.success(this.returnMsg, "نجاح");
      }
    });
  }

  Save2(user2) {
    this.returnMsg2 = "";
    this._app.changePassword(this.user2).subscribe(x => {
      let status = x["status"] as Boolean;
      if (status == true) {
        localStorage.removeItem("user");
        this.router.navigate(["/login"]);
      } else {
        this.toastr.error(this.returnMsg2, "خطأ");
        this.returnMsg2 = x[appConstant.MESSAGE] as string;
      }
    });
  }
}
