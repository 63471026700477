import { Component, OnInit, Input } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { appConstant } from "src/app/services/_constant/appConstant";

@Component({
  selector: "app-transform-modal",
  templateUrl: "./transform-modal.component.html",
  styleUrls: ["./transform-modal.component.css"]
})
export class TransformModalComponent implements OnInit {
  @Input() order: any;
  constructor(
    private _app: AppOperationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit() {


  }

  updateStatus() {
    let content = {
      status: 2
    };
    this._app.updateOrderByUser(content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }
      // setTimeout(() => {
      //   btn2.click();
      // }, 1000);
      // this.router.navigate(["/profile"]);
      this.router.navigate(["/history"]);
    });
  }

  update(btn) {
    // if (this.order.category_id._id == "5db872a009a9b47513be77e3") {
    //   this.updateStatus();
    //   return
    // }
    let content = {
      status: 3
    };
    this._app.updateOrderByUser(content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }

      btn.click();
      // this.router.navigate(["/profile"]);
      this.router.navigate(["/history"]);
    });
  }
}
