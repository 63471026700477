import { BadgeService } from './../../services/badge.service';
import { ServiceService } from './../../services/service.service';
import { AppOperationService } from './../../services/app-operation.service';
import { Component, OnInit, AfterContentInit } from '@angular/core';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { NgxSpinnerService } from 'ngx-spinner';
import { switchMap, mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  categories = []
  settings = []
  news = []
  last_order_id
  tran_ref
  amount = 0.0
  isLogin = false;
  constructor(private BadgeService: BadgeService, private toastr: ToastrService, private dynamicScriptLoader: ServiceService, private _app: AppOperationService, private spinner: NgxSpinnerService) {

  }

  ngOnInit() {
    this._app.getSettings().subscribe(x => {
      this.settings = x[appConstant.ITEMS] as any[]
    })
    
    this._app.getCategoriesAdmin().subscribe(x => {
      this.categories = x[appConstant.ITEMS] as any[]
    })

    this._app.getNews(4).subscribe(x => {
      this.news = x[appConstant.ITEMS] as any[]
    })

    if (this._app.checkLoginUser() == false) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  }

    // setTimeout(() => {
    //   this.tran_ref = localStorage.getItem("tran_ref")
    //   if(this.tran_ref){
    //     this._app.CheckPay({tran_ref:this.tran_ref}).subscribe(x=>{
    //       let status = x[appConstant.STATUS] as boolean
    //       if(status){
    //         this.updateUserWallet()
    //       }else{
    //         this.toastr.error(x[appConstant.MESSAGE] , "خطأ");
    //       }
    //     })
    //   }
    // }, 1000);

  

  // updateUserWallet(){
  //   let obj = {
  //      wallet:localStorage.getItem("value")
  //   }
  //   this._app.updateUserWallet(obj).subscribe(x=>{
  //       let msg = x[appConstant.MESSAGE]
  //       if(x[appConstant.STATUS]){
  //         this.toastr.success(msg,"نجاح");
  //         localStorage.removeItem("value")
  //         localStorage.removeItem("tran_ref")
  //       }else{
  //         this.toastr.success(msg,"خطأ");
  //       }
  //     })
  // }
}
