export class appConstant {
  public static SUM = "sum";
  public static ITEMS = "items";
  public static MESSAGE = "message";
  public static PAGINATION = "pagenation";
  public static STATUS_CODE = "status_code";
  public static STATUS = "status";
  public static BASE_URL = "https://dhaminweb.herokuapp.com/api/";
  // public static BASE_URL = "https://api.dhamin.sa/api/";
  // public static BASE_URL = "http://0.0.0.0:3000/api/";
}
