import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { appConstant } from "./_constant/appConstant";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class AppOperationService {

  userData = new BehaviorSubject<any>(localStorage.getItem("userData"));

  constructor(private http: HttpClient) {}

  ///helper///
  getHeaderWithToken(){
    return {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        token: this.getToken()
      })
    };
  }

  getHeaderWithoutToken(){
    return  {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
      })
    };
  }

  getToken() {
    let token = localStorage.getItem("user");
    return token;
  }
  ////////////
  getStaticPagenData() {
    return this.http.get(appConstant.BASE_URL + "/constant/staticpage");
  }

  getSingleStaticPagenData(id) {
    return this.http.get(appConstant.BASE_URL + `staticpage/${id}`);
  }

  getContactOptionData() {
    return this.http.get(appConstant.BASE_URL + "Contact");
  }

  getSocialOptionData() {
    return this.http.get(appConstant.BASE_URL + "SocialOption/1");
  }


  getAllSocialOptionData() {
    return this.http.get(appConstant.BASE_URL + "social");
  }

  getOrderDetails(id) {
    return this.http.get(appConstant.BASE_URL + "/order/getOrderDetails/" + id);
  }


  advancedSearch(conent, page) {
    return this.http.post(
      appConstant.BASE_URL +
        "/Supplier/advanceSearch?page=" +
        page +
        "&limit=15",
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  // postAddRate(id, content) {
  //   let userInfo = JSON.parse(localStorage.getItem("user"));
  //   let token = userInfo["token"];

  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Content: "application/json",
  //       Accept: "application/json",
  //       token: token,
  //       "Content-Type": "application/json; charset=utf-8",
  //       "Access-Control-Allow-Origin": "*"
  //     })
  //   };

  //   return this.http.post(
  //     appConstant.BASE_URL + "/order/addRate/" + id,
  //     JSON.stringify(content),
  //     httpOptions
  //   );
  // }

  postAddRateOther(id, content) {
    let userInfo = JSON.parse(localStorage.getItem("user"));
    let token = userInfo["token"];

    const httpOptions = {
      headers: new HttpHeaders({
        Content: "application/json",
        Accept: "application/json",
        token: token,
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*"
      })
    };

    return this.http.post(
      appConstant.BASE_URL + "/order/addRateOther/" + id,
      JSON.stringify(content),
      httpOptions
    );
  }

  updateRefNo(content){
    return this.http.post(
      appConstant.BASE_URL + "updateRefNo",
      JSON.stringify(content),
      this.getHeaderWithToken()
    );
  }

  adduser(conent) {
    return this.http.post(
      appConstant.BASE_URL + "/User/addWeb",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }

  verifyUser(conent) {
    return this.http.post(
      appConstant.BASE_URL + "verify",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }

  CheckPay(content) {
    return this.http.post(
      appConstant.BASE_URL + "CheckPay",
      JSON.stringify(content),
      this.getHeaderWithoutToken()
    );
  }

  checkLoginUser(): boolean {
    let userInfo = localStorage.getItem("user");
    if (userInfo) {
      return true;
    } else {
      return false;
    }
  }

  addContact(conent) {
    return this.http.post(
      appConstant.BASE_URL + "contact",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }


  getNews(limit) {
    return this.http.get(appConstant.BASE_URL + "news?page=0&limit=" + limit);
  }

  getsingleNews(id) {
    return this.http.get(appConstant.BASE_URL + "singlenews/" + id);
  }

  getSettings() {
    return this.http.get(appConstant.BASE_URL + "settings");
  }

  getCategoriesAdmin() {
    return this.http.get(appConstant.BASE_URL + "getCategoriesAdmin");
  }

  postPayment(content) {
    return this.http.post(
      appConstant.BASE_URL + "pay",
      JSON.stringify(content),
      this.getHeaderWithToken()
    );
  }

  addPaymentRequest(content) {
    return this.http.post(
      appConstant.BASE_URL + "add-payRequest",
      JSON.stringify(content),
      this.getHeaderWithToken()
    );
  }

  addUser(conent) {
    return this.http.post(
      appConstant.BASE_URL + "users",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }

  loginUser(conent) {
    return this.http.post(
      appConstant.BASE_URL + "login",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }

  resetPasswordUser(conent) {
    return this.http.post(
      appConstant.BASE_URL + "forgetPassword",
      JSON.stringify(conent),
      this.getHeaderWithoutToken()
    );
  }

  getUser() {
    return this.http.get(appConstant.BASE_URL + "user", this.getHeaderWithToken());
  }

  getUserWallet(id) {
    return this.http.get(appConstant.BASE_URL + "getAllUsersWallet/"+id, this.getHeaderWithToken());
  }

  updateprofile(conent) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        token: this.getToken(),
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    };
    const formData: FormData = new FormData();
    formData.append("email", conent.email);
    formData.append("address", conent.address);
    formData.append("full_name", conent.full_name);
    formData.append("phone_number", conent.phone_number);
    formData.append("image", conent.image);
    formData.append("iban", conent.iban);

    return this.http.post(
      appConstant.BASE_URL + "updateprofile",
      formData,
      httpOptions
    );
  }

  changePassword(conent) {
    return this.http.post(
      appConstant.BASE_URL + "changePassword",
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  getuserOpperation() {
    return this.http.get(appConstant.BASE_URL + "getUserOrder?page=0&limit=10",this.getHeaderWithToken());
  }

  getuserNotifications() {
    return this.http.get(
      appConstant.BASE_URL + "notifications?page=0&limit=10",
      this.getHeaderWithToken()
    );
  }

  getuserRatings() {
    return this.http.get(
      appConstant.BASE_URL + "getUserRating?page=0&limit=100",
      this.getHeaderWithToken()
    );
  }



  getOtherUserRatings(phone) {
    return this.http.get(
      appConstant.BASE_URL + "getOtherUserRating?page=0&limit=100&phone="+phone,
      this.getHeaderWithToken()
    );
  }

  updateWallet(conent) {
    return this.http.post(
      appConstant.BASE_URL + "wallet",
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  userAdvancedSearch(conent) {
    return this.http.post(
      appConstant.BASE_URL + "userAdvancedSearch?page=0&limit=10",
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  getSingleCategoriesAdmin(id) {
    return this.http.get(appConstant.BASE_URL + "category/" + id);
  }

  addOrder(conent, fileToUpload: File) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        token: this.getToken(),
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    };
    const formData: FormData = new FormData();
    formData.append("body", conent.body);
    formData.append("title", conent.title);
    formData.append("other_jawwal", conent.other_jawwal);
    formData.append("delivery_date", conent.delivery_date);
    formData.append("ammount", conent.ammount);
    formData.append("category_id", conent.category_id);
    formData.append("file", conent.file);
    formData.append("file1", conent.file1);
    formData.append("file2", conent.file2);
    formData.append("file3", conent.file3);
    formData.append("userType", conent.userType);
    formData.append("paymentBy", conent.paymentBy);

    return this.http.post(
      appConstant.BASE_URL + "order",
      formData,
      httpOptions
    );
  }

  updateOrder(conent, fileToUpload: File, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Content: "multipart/form-data",
        token: this.getToken(),
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    };
    const formData: FormData = new FormData();
    formData.append("body", conent.body);
    formData.append("title", conent.title);
    formData.append("other_jawwal", conent.other_jawwal);
    formData.append("delivery_date", conent.delivery_date);
    formData.append("ammount", conent.ammount);
    formData.append("category_id", conent.category_id);
    formData.append("file", conent.file);
    formData.append("file1", conent.file1);
    formData.append("file2", conent.file2);
    formData.append("file3", conent.file3);

    return this.http.post(
      appConstant.BASE_URL + "updateOrder/" + id,
      formData,
      httpOptions
    );
  }

  getSingleOrder(id) {
    return this.http.get(appConstant.BASE_URL + "getOrderDetails/" + id);
  }

  updateOrderByUser(conent, id) {
    return this.http.post(
      appConstant.BASE_URL + "updateOrderByUser/" + id,
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }


  updateUserWallet(conent) {
    return this.http.post(
      appConstant.BASE_URL + "wallet" ,
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  updateOrderCode(conent, id) {
    return this.http.post(
      appConstant.BASE_URL + "updateOrderCode/" + id,
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  checkOrderCode(conent, id) {
    return this.http.post(
      appConstant.BASE_URL + "checkOrderCode/" + id,
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }
  addRate(conent, id) {
    return this.http.post(
      appConstant.BASE_URL + "addRate/" + id,
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  addDispute(conent) {
    return this.http.post(
      appConstant.BASE_URL + "addDispute",
      JSON.stringify(conent),
      this.getHeaderWithToken()
    );
  }

  getuserMenuNotifications() {
    return this.http.get(
      appConstant.BASE_URL + "notifications?page=0&limit=4",
      this.getHeaderWithToken()
    );
  }
}
