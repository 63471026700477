import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  @HostListener('input', ['$event']) onInput(event: any): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    
    // Remove any characters that are not numbers
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
    
    if (inputValue !== sanitizedValue) {
      input.value = sanitizedValue;
      event.preventDefault();
    }
  }
}
