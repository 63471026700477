import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.css']
})
export class CategoryMenuComponent implements OnInit {

  categories = []
  constructor(private _app: AppOperationService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this._app.getCategoriesAdmin().subscribe(x => {
      this.categories = x[appConstant.ITEMS] as any[]
    })
  }
}
