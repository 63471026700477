import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  persons = []
  ratings = []
  constructor(private _app: AppOperationService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  getRatingList(phone){
    this._app.getOtherUserRatings(phone).subscribe(x => {
      this.ratings = x[appConstant.ITEMS] as any[]
      //this.rating =  x['rating'] as any
    })
  }

  search(val) {
    if(val != "" && val != " "){
      let content = { val: val }
      this._app.userAdvancedSearch(content).subscribe(x => {
        this.persons = x[appConstant.ITEMS] as any[]
        this.getRatingList(val)
      })
    }
  }

  counter(i: number) {
    return new Array(i);
  }
  getWidth(val) {
    return val + "%"
  }
}
