import { AppOperationService } from "./../../services/app-operation.service";
import { Component, OnInit } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { MouseEvent } from "@agm/core";
import { BadgeService } from "./../../services/badge.service";

@Component({
  selector: "app-checkout-result",
  templateUrl: "./checkout-result.component.html",
  styleUrls: ["./checkout-result.component.css"]
})
export class CheckoutResultComponent implements OnInit {
  response = {
    result: "",
    response_code: "",
    pt_invoice_id: "",
    amount: 0,
    currency: "",
    reference_no: "",
    transaction_id: ""
  };

  constructor(
    private BadgeService: BadgeService,
    private router: Router,
    private _app: AppOperationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    
  }
}
