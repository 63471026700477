import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.css']
})
export class StaticPageComponent implements OnInit {

  details = {
    title: '',
    content: ''
  };
  id = ''

  constructor(private _app: AppOperationService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'] == undefined ? null : params['id'];
      this.spinner.show()
      this._app.getSingleStaticPagenData(this.id).subscribe(x => {
        this.details = x[appConstant.ITEMS] as any;
        // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
        let plainText2 = x['content'].replace(/&quot;/g, '"')
        this.details.title = x['title']
        this.details.content = plainText2
        this.spinner.hide()
      });
    });
  }
}
