import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.css']
})
export class RechargeComponent implements OnInit {

  details = {
    title: '',
    content: ''
  };
  id = ''
  wallets_arr = []
  wallet = 0.0;
  user;
  MyIp;
  hide_button = false;

  constructor(
    private _app: AppOperationService, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getuser()
    this.spinner.show()
    this._app.getSingleStaticPagenData("63dd4182cc9737fa8d148b41").subscribe(x => {
      this.details = x[appConstant.ITEMS] as any;
      // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
      let plainText2 = x['content'].replace(/&quot;/g, '"')
      this.details.title = x['title']
      this.details.content = plainText2
      this.spinner.hide()
    });
  }


  getUserWallet(id){
    this._app.getUserWallet(id).subscribe(x => {
      this.wallets_arr = x[appConstant.ITEMS] as any[];
    });

  }

  getuser() {
    this._app.getUser().subscribe(x => {
      this.wallet = x[appConstant.ITEMS]["wallet"] as any;
      this.user = x[appConstant.ITEMS];
      this.getUserWallet(this.user._id)
    });

    this.http.get<{ ip: string }>("https://jsonip.com").subscribe(data => {
      this.MyIp = data["ip"];
    });
  }

  addPaymentReq(value){
    if(!this.user.iban){
      this.toastr.error("الرجاء ادخال رقم الايبان في صفحة بياناتي لتتمكن من التحويل لحسابك البنكي","خطأ");
      return;
    }
    if(Number(value) > Number(this.wallet)){
      this.toastr.error("الرجاء ادخال قيمة اقل من قيمة رصيد المحفظة الخاص بكم","خطأ");
      return;
    }
    if(value == '0'  || value == '' || value == undefined || value == null || Number(value) <= 0){
      this.toastr.error("الرجاء ادخل المبلغ","خطأ");
      return;
    }
    var payment = {
      amount:value,
    }
    this.hide_button = true;
    this._app.addPaymentRequest(payment).subscribe(x=>{
      this.hide_button = false;
      let msg = x[appConstant.MESSAGE];
      if(x[appConstant.STATUS]){
          this.toastr.success(msg,"نجاح");
          this.router.navigate(["/profile",{ outlets: { profile: [ 'wallet' ] }}]);
      }else{
        this.toastr.error(msg,"خطأ");
      }
    });
  }
}
