import { Component, OnInit } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-profile-wallet",
  templateUrl: "./profile-wallet.component.html",
  styleUrls: ["./profile-wallet.component.css"]
})
export class ProfileWalletComponent implements OnInit {
  wallet = 0.0;
  returnMsg = "";
  user;
  MyIp;
  isSelectedOnline = true;
  isSelectedBank = false;
  hide_button = false;
  type
  payments = [
    {id:1,name:"الدفع الالكتروني",img:"../../../assets/images/visa.svg"},
    {id:2,name:"تحويل بنكي",img:"../../../assets/images/bank.svg"}]
  pyamentType = 2
  wallets_arr = []
  
  constructor(
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) {}
  ngOnInit() {
    this.getuser();
  }

  getuser() {
    this._app.getUser().subscribe(x => {
      this.wallet = x[appConstant.ITEMS]["wallet"] as any;
      this.user = x[appConstant.ITEMS];
      this.getUserWallet(this.user._id)
    });

    this.http.get<{ ip: string }>("https://jsonip.com").subscribe(data => {
      this.MyIp = data["ip"];
    });
  }


  getUserWallet(id){
    this._app.getUserWallet(id).subscribe(x => {
      this.wallets_arr = x[appConstant.ITEMS] as any[];
    });

  }
  makeid() {
    var text = "";
    var possible = "0123456789";

    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  updateWallet(value) {
    if(!this.pyamentType){
      this.toastr.error("الرجاء اختيار وسيلة الدفع","خطأ");
      return;
    }
    if(value == '0'  || value == '' || value == undefined || value == null || Number(value) <= 0){
      this.toastr.error("الرجاء ادخل المبلغ","خطأ");
      return;
    }
    var payment = {
      conent:"شحن محفظة",
      cart_id:this.makeid(),
      ammount:value,
      type: '1'
    }
    this._app.postPayment(payment).subscribe(x=>{
      let msg = x[appConstant.MESSAGE];
      if(x[appConstant.STATUS]){
         let res  = x[appConstant.ITEMS] as any
         console.log(res)
          //let url = res.redirect_url;
          //localStorage.setItem("tran_ref", res.tran_ref);
          //localStorage.setItem("value", value);
          //window.location.replace(url);          
          this.toastr.success(msg,"نجاح");
      }else{
        this.toastr.error(msg,"خطأ");
      }
    });
  }

  addPaymentReq(value){
    if(!this.user.iban){
      this.toastr.error("الرجاء ادخال رقم الايبان في صفحة بياناتي لتتمكن من التحويل لحسابك البنكي","خطأ");
      return;
    }
    if(Number(value) > Number(this.wallet)){
      this.toastr.error("الرجاء ادخال قيمة اقل من قيمة رصيد المحفظة الخاص بكم","خطأ");
      return;
    }
    if(value == '0'  || value == '' || value == undefined || value == null || Number(value) <= 0){
      this.toastr.error("الرجاء ادخل المبلغ","خطأ");
      return;
    }
    var payment = {
      amount:value,
    }
    this.hide_button = true;
    this._app.addPaymentRequest(payment).subscribe(x=>{
      this.hide_button = false;
      let msg = x[appConstant.MESSAGE];
      if(x[appConstant.STATUS]){
          this.toastr.success(msg,"نجاح");
      }else{
        this.toastr.error(msg,"خطأ");
      }
    });
  }

  checkPayment(Obj){
    this.pyamentType = Obj.id
  }

  updateRefrence(value){
    if(!this.pyamentType){
      this.toastr.error("الرجاء اختيار وسيلة الدفع","خطأ");
      return;
    }
    if(value == '' || value == undefined || value == null){
      this.toastr.error("الرجاء ادخل الرقم المرجعي","خطأ");
      return;
    }

    let body = { ref_no: value }
    this._app.updateRefNo(body).subscribe(x=>{
    let msg = x[appConstant.MESSAGE]
      if(x[appConstant.STATUS]){
        this.getuser();
        this.toastr.success(msg,"نجاح");
      }else{
        this.toastr.error(msg,"خطأ");
      }
    })
  }

  choosePaymet(type){
    this.type = type;
    if (type == 'online'){
      this.isSelectedOnline = true;
      this.isSelectedBank = false;
    }else{
      this.isSelectedOnline = false;
      this.isSelectedBank = true;
    }
  }
}
