import { BadgeService } from "./../../services/badge.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppOperationService } from "./../../services/app-operation.service";
import { Component, OnInit } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ViewChild, ElementRef } from "@angular/core";
import { ServiceService } from "src/app/services/service.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  @ViewChild("closeAddExpenseModal") closeAddExpenseModal: ElementRef;

  categories = [];
  items = [];
  register = false;
  isLogin = false;
  user_name = "";
  UserId = "";
  total = 0.0;
  delivery_cost = 10.0;
  selectedValue = "اختر التصنيف";
  isBasket = false;
  isActive = false;
  user;
  socialPages = [];
  notifictions = [];
  constructor(
    private badgeService: BadgeService,
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dynamicScriptLoader: ServiceService,
  ) {
    this.loadScripts()
  }

  ngOnInit() {
    this._app.getSocialOptionData().subscribe(_users => {
      this.socialPages = _users[appConstant.ITEMS] as any[];
    });
    this.toggle();
    this._app.getuserMenuNotifications().subscribe(x => {
      this.notifictions = x[appConstant.ITEMS] as any[];
    });

    this.loadScripts()
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("expire");
    localStorage.removeItem("isAgree");
    this.toggle();
  }

  toggle() {
    if (this._app.checkLoginUser() == false) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
      this._app.getUser().subscribe(x => {
        this.user = x[appConstant.ITEMS] as any;
      });
    }
  }

  openMenu() {
    this.toggle()
    document.getElementsByTagName("body")[0].classList.add("menu-toggle");
    document.getElementsByClassName("hamburger")[0].classList.add("active");
  }

  private loadScripts() {
    this.dynamicScriptLoader
      .load(
        // "script1",
        // "script3",
        "script4",
        "script5",
        "script6",
        "script7",
        "script8",
        "script9",
        "script10",
        "script11",
        "script12"
      )
      .then(data => {
        // Script Loaded Successfully
      })
      .catch(error => console.log(error));
  }
}
