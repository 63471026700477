import { AppOperationService } from "./../../services/app-operation.service";
import { Component, OnInit } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  pages = [];
  phone = "";
  jawwal = "";
  socialPages = [];
  isLogin = false;
  constructor(private _app: AppOperationService) {
    if (localStorage.getItem("user")) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
  }

  ngOnInit() {
    // this._app.getStaticPagenData().subscribe(userList => {
    //   this.pages = userList as any[];
    // });

    // this._app.getContactOptionData().subscribe(_users => {
    //   this.phone = _users[0]["data"] as any;
    //   this.jawwal = _users[1]["data"] as any;
    // });

    this._app.getSocialOptionData().subscribe(_users => {
      this.socialPages = _users[appConstant.ITEMS] as any[];
    });
  }
}
