import { AppOperationService } from "src/app/services/app-operation.service";
import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.css"]
})
export class ContractComponent implements OnInit {
  fileToUpload: File = null;
  max = 0;
  commission = 0;
  categories = [];
  wallet;
  user;
  name;
  name2;
  image;
  id;
  cid;
  returnMsg = "";
  imageName;
  imageName1;
  imageName2;
  imageName3;
  isEdit = false;

  contract = {
    body: "",
    title: "",
    other_jawwal: "",
    delivery_date: null,
    first_ammount: 0,
    file: null,
    file1: null,
    file2: null,
    file3: null,
    ammount: this.commission,
    category_id: "",
    OrderId: "",
    userType: 0,
    paymentBy: 0
  };

  phonteTitle = "رقم الجوال";
  isAgree = false;
  fileName = "";
  fileName1 = "";
  fileName2 = "";
  fileName3 = "";

  owner = false;
  userType = [];
  userType1 = [
    { id: 1, name: "مشتري" },
    { id: 2, name: "بائع" }
  ];

  userType2 = [
    { id: 1, name: "صاحب الطلب" },
    { id: 2, name: "مندوب" }
  ];

  userType3 = [
    { id: 1, name: "أنا ملزم بالدفع" },
    { id: 2, name: "الطرف الاخر ملزم بالدفع" }
  ];
  showDatepicker = true;
  isUplaod = false;
  isClicked = false
  disabled = false;
  
  constructor(
    private _app: AppOperationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private el: ElementRef
  ) {  }

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.id = params["id"] == undefined ? null : params["id"];
      this.cid = params["cid"] == undefined ? null : params["cid"];
      this.phonteTitle = "رقم الجوال";

      this.spinner.show();

    
      if (this.id) {
        this._app.getSingleCategoriesAdmin(this.id).subscribe(x => {
          this.max = x[appConstant.ITEMS]["max"] as any;
          this.commission = x[appConstant.ITEMS]["commission"] as any;
          this.contract.ammount = this.commission

          this.name = x[appConstant.ITEMS]["name"] as any;
          this.name2 = x[appConstant.ITEMS]["name2"] as any;
          this.image = x[appConstant.ITEMS]["image"] as any;
          if (this.id == "5db8728509a9b47513be77e2") {
            this.userType = this.userType1;
          }
          if (this.id == "5db872a009a9b47513be77e3") {
            this.userType = this.userType2;
            this.phonteTitle = "قم بادخال جوال صاحب الطلب"
          }
          if (this.id == "5db872b309a9b47513be77e4") {
            this.userType = this.userType3;
            this.phonteTitle = "رقم جوال الطرف الاخر"
          }
          this.makeid();


          let firstContract = JSON.parse(localStorage.getItem("firstContract"));
          if(firstContract){
            this.contract = firstContract
            this.sumCommision()
          }
        });
      }
      if (this.cid) {
        this._app.getSingleOrder(this.cid).subscribe(x => {
          this.isEdit = true;
          this.contract = x[appConstant.ITEMS] as any;
          this.contract.first_ammount = this.contract.ammount - this.commission;
          // let date = this.formatDate(this.contract.delivery_date);
          this.contract.delivery_date = x[appConstant.ITEMS]["delivery_date"];
          if (this.id == "5db8728509a9b47513be77e2") {
            if (this.contract.userType == 1) {
              this.phonteTitle = "رقم جوال البائع";
            }
            if (this.contract.userType == 2) {
              this.phonteTitle = "رقم جوال المشتري";
            }
          } else if (this.id == "5db872a009a9b47513be77e3") {
            if (this.contract.userType == 1) {
              this.phonteTitle = "رقم جوال المندوب";
            }
            if (this.contract.userType == 2) {
              this.phonteTitle = "رقم جوال صاحب الطلب";
            }
          } else {
            this.phonteTitle = "رقم جوال الطرف الاخر";
          }
        });
      }


    });

    this._app.getCategoriesAdmin().subscribe(x => {
      this.categories = x[appConstant.ITEMS] as any[];
    });

    this._app.getUser().subscribe(x => {
      this.user = x[appConstant.ITEMS] as any;
      this.wallet = this.user.wallet;
    });
  }

  changeStatus(val) {
    this.isAgree = val;
  }

  processImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.contract.file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      this.imageName = event.target.files;
      var reader = new FileReader();
      this.isUplaod = true
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = _event => {};
    }
  }

  processImage1(event) {
    if (event.target.files && event.target.files[0]) {
      this.contract.file1 = event.target.files[0];
      this.fileName1 = event.target.files[0].name;
      this.imageName1 = event.target.files;
      var reader = new FileReader();
      this.isUplaod = true
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = _event => {};
    }
  }

  processImage2(event) {
    if (event.target.files && event.target.files[0]) {
      this.contract.file2 = event.target.files[0];
      this.fileName2 = event.target.files[0].name;
      this.imageName2 = event.target.files;
      var reader = new FileReader();
      this.isUplaod = true
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = _event => {};
    }
  }

  processImage3(event) {
    if (event.target.files && event.target.files[0]) {
      this.contract.file3 = event.target.files[0];
      this.fileName3 = event.target.files[0].name;
      this.imageName3 = event.target.files;
      var reader = new FileReader();
      this.isUplaod = true
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = _event => {};
    }
  }

  save(obj, date, button) {

    if (this.id == "5db872b309a9b47513be77e4") {
       this.contract.userType = 1;
    }

    if (this.id == "5db872a009a9b47513be77e3") {
      this.contract.userType = 2;
    }

    if (!this.user) {
      this.contract.category_id = this.id
      localStorage.setItem("firstContract",JSON.stringify(this.contract))
      setTimeout(() => {
        button.click();
        this.router.navigate(["/login"]);    
        return;
      }, 3000);
    }else{
      // let firstContract = JSON.parse(localStorage.getItem("firstContract"));
      // if(firstContract)
      //   localStorage.removeItem("firstContract");
    }
    
    //debugger;
    const today = new Date();
    if (this.id == "5db872a009a9b47513be77e3") {
      this.contract.delivery_date = today;
    } else{
      this.contract.delivery_date = date;
      let dd = new Date(date);
      let newDate = dd.setDate(dd.getDate() + 1);
      let curretDate = today.setDate(today.getDate());
      if (newDate <= curretDate){
        this.toastr.error("الرجاء ادخال تاريخ صحيح", "خطأ");
        return;
      }
    }



    this.contract.category_id = this.id;
    if (this.isAgree != true) {
      this.toastr.error("الرجاء الموافقة على الشروط والأحكام", "خطأ");
      return;
    }
    if (this.contract.userType == 0) {
      this.toastr.error("الرجاء تحديد نوع المستخدم", "خطأ");
      return;
    }
    if (this.contract.other_jawwal === this.user.phone_number) {
      this.toastr.error("لا يمكن ان يكون رقم جوال الطرف الاخر هو نفس رقمك", "خطأ");
      return;
    }
    this.disabled = true
    if (this.isEdit == true) {
      // update
      this._app
        .updateOrder(this.contract, this.fileToUpload, this.cid)
        .subscribe(x => {
          let status = x["status"] as boolean;
          let msg = x[appConstant.MESSAGE] as string;

          if (status == false) {
            this.toastr.error(msg, "خطأ");
          } else {
            this.toastr.success(msg, "نجاح");
          }

          button.click();
          this.contract = {
            body: "",
            title: "",
            other_jawwal: "",
            delivery_date: null,
            first_ammount: 0,
            file: null,
            file1: null,
            file2: null,
            file3: null,
            ammount: this.commission,
            category_id: "",
            OrderId: "",
            userType: 0,
            paymentBy: 0
          };
          this.isEdit = false;
          this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
        });
    } else {
      // add
      if (!this.user) {
        this.disabled = false
        localStorage.setItem("firstContract",JSON.stringify(this.contract))
        button.click();
        setTimeout(() => {
          this.router.navigate(["/login"]);
        }, 1000);
        return;
      }
      this.isClicked = true
      this._app.addOrder(this.contract, this.fileToUpload).subscribe(x => {
        let status = x["status"] as boolean;
        let msg = x[appConstant.MESSAGE] as string;
        this.isClicked = false
        if (status == false) {
          this.toastr.error(msg, "خطأ");
        } else {
          this.toastr.success(msg, "نجاح");
          let firstContract = JSON.parse(localStorage.getItem("firstContract"));
          if(firstContract)
            localStorage.removeItem("firstContract");
        }
        
        this.disabled = false
        button.click();
        this.contract = {
          body: "",
          title: "",
          other_jawwal: "",
          delivery_date: null,
          first_ammount: 0,
          file: null,
          file1: null,
          file2: null,
          file3: null,
          ammount: this.commission,
          category_id: "",
          OrderId: "",
          userType: 0,
          paymentBy: 0
        };
        this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
      });
    }
  }

  sumCommision() {
    this.contract.ammount = Number(this.contract.first_ammount) + this.commission;
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  onItemChange(item) {
    this.owner = item;
  }
  makeid() {
    var text = "";
    var possible = "0123456789";

    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    this.contract.OrderId = text;
  }

  onSelectionUserType(id) {
    this.contract.userType = id;
    if (this.id == "5db8728509a9b47513be77e2") {
      if (id == 1) {
        this.phonteTitle = "رقم جوال البائع";
      }
      if (id == 2) {
        this.phonteTitle = "رقم جوال المشتري";
      }
    } else if (this.id == "5db872a009a9b47513be77e3") {
      if (id == 1) {
        this.phonteTitle = "رقم جوال المندوب";
      }
      if (id == 2) {
        this.phonteTitle = "رقم جوال صاحب الطلب";
      }
    } else {
      this.phonteTitle = "رقم جوال الطرف الاخر";
    }
  }

  onSelectionUserType2(id) {
    this.contract.paymentBy = id;
  }

  openMenu() {
    document.getElementsByTagName("body")[0].classList.add("menu-toggle2");
    document.getElementsByClassName("hamburger2")[0].classList.add("active");
  }

  onSearchChange(val){
  }

  detectMax(val){
    if(Number(val)>this.max){
      this.contract.first_ammount = this.max
      this.toastr.info("الحد الأقصى هو: "+ this.max  + " ريال ", "تحذير")
    }
    this.sumCommision()
  }

  focusFunction(){
    let x = this.contract.first_ammount 
    this.contract.first_ammount = "" as any
    this.contract.ammount = this.commission;
  }
}
