import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit {
  @ViewChild("btnRefresh") btnRefresh: ElementRef;

  user = {
    full_name: "",
    email: "",
    password: "",
    phone_number: "",
    verify_code: "",
    iban: ""
  };
  
  returnMsg = "";
  isAgree = false;
  isRegister = false;
  isFound = true;

  constructor(
    private _app: AppOperationService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    
  }

  Save(content) {
    this.returnMsg = "";
    if (!this.isAgree) {
      this.returnMsg = "الرجاء الموافقة على الشروط والأحكام";
      this.toastr.error(this.returnMsg, "خطأ");
      return;
    }
    this.isRegister = true;

    this._app.addUser(this.user).subscribe(x => {
      let status = x["status"] as Boolean;
      this.returnMsg = x[appConstant.MESSAGE] as string;
      // this.isAgree = false;

      if (status == false) {
        this.toastr.error(this.returnMsg, "خطأ");
        return;
      } else {
        if (x["isFound"] == true) {
          this.isFound = true;
        } else {
          this.isFound = false;
        }
        // localStorage.setItem("user", x[appConstant.ITEMS]["token"]);
        this.isRegister = true;
      }
    });
  }

  changeStatus(val) {
    this.isAgree = val;
  }

  Verify() {
    this.returnMsg = "";
    this._app.verifyUser(this.user).subscribe(x => {
      let status = x["status"] as Boolean;
      this.returnMsg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(this.returnMsg, "خطأ");
      } else {
        this.toastr.success(this.returnMsg, "نجاح");
        localStorage.setItem("user", x[appConstant.ITEMS]["token"]);
        localStorage.setItem("expire", x[appConstant.ITEMS]["expire"]);

        debugger
        let firstContract = JSON.parse(localStorage.getItem("firstContract"));
        if(firstContract)
        {          
          setTimeout(() => {
            this.btnRefresh.nativeElement.click()
          }, 1000);
        }
        else{
          setTimeout(() => {
            this.btnRefresh.nativeElement.click()
          }, 1000);
        }
        // this.router.navigate(["/home"]);
      }
    });
  }
}
