import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @ViewChild("btnRefresh") btnRefresh: ElementRef;

  user = {
    phone_number: "",
    verify_code: ""
  };
  returnMsg = "";
  isRegister = false;

  constructor(
    private _app: AppOperationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() { }

  Save(content) {
    this.returnMsg = "";

    this._app.loginUser(this.user).subscribe(x => {
      let status = x["status"] as Boolean;
      this.returnMsg = x[appConstant.MESSAGE] as string;

      if (status == false) {
        this.toastr.error(this.returnMsg, "خطأ");
      } else {
        this.toastr.success(this.returnMsg, "نجاح");
      }

      if (status) {
        // redirect
        this.isRegister = true;
        // localStorage.setItem("user", x[appConstant.ITEMS]["token"]);
        // this.router.navigate(["/home"]);
      }
    });
  }

  Verify() {
    this.returnMsg = "";
    this._app.loginUser(this.user).subscribe(x => {
      let status = x["status"] as Boolean;
      this.returnMsg = x[appConstant.MESSAGE] as string;

      if (status == false) {
        this.toastr.error(this.returnMsg, "خطأ");
      } else {
        this.toastr.success(this.returnMsg, "نجاح");
      }

      if (status) {
        // redirect
        // this.isRegister = true;
        localStorage.setItem("user", x[appConstant.ITEMS]["token"]);
        setTimeout(() => {
          this.btnRefresh.nativeElement.click()
        }, 1000);
      }
    });
  }
}
