import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateMask]',
})
export class DateMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const input = event.target;
    const value = input.value.replace(/\D/g, ''); // Remove non-numeric characters

    if (value.length <= 8) {
      input.value = this.formatDate(value);

      // Check for valid date
      if (this.isValidDate(input.value)) {
        this.clearInput(input); // Clear the input if the date is valid
      }
    } else {
      input.value = this.formatDate(value.substring(0, 8));
    }
  }

  private formatDate(input: string): string {
    const day = input.slice(0, 2);
    const month = input.slice(2, 4);
    const year = input.slice(4, 8);
    return `${day}/${month}/${year}`;
  }

  private isValidDate(dateString: string): boolean {
    const dateParts = dateString.split('/');
    if (dateParts.length !== 3) {
      return false;
    }

    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Month is zero-based in JavaScript
    const year = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);

    return (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    );
  }

  private clearInput(input: HTMLInputElement): void {
    input.value = ''; // Clear the input field
  }
}
