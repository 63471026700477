import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";

@Component({
  selector: "app-end",
  templateUrl: "./end.component.html",
  styleUrls: ["./end.component.css"]
})
export class EndComponent implements OnInit {
  @Input() order: any;
  @ViewChild("btnRefresh") btnRefresh: ElementRef;
  myId;
  code = "TEST CODE";
  constructor(
    private _app: AppOperationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {
    this._app.getUser().subscribe(x => {
      this.myId = x[appConstant.ITEMS]._id as any;
    });
    this.makeid();
  }

  //4-5
  update(btn) {
    var content;
    if (this.order.status == 3) {
      content = { status: 4 };
    } else if (this.order.status == 4) {
      content = { status: 5 };
    }

    this._app.updateOrderByUser(content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }
      if (this.order.category_id.code == "Delivery") {
        let content2 = { checkCode: this.code };
        if(this.order.status == 4){
          this._app.updateOrderCode(content2, this.order._id).subscribe(x => {
            let status = x["status"] as boolean;
            let msg = x[appConstant.MESSAGE] as string;
            if (status == false) {
              this.toastr.error(msg, "خطأ");
            } else {
              this.toastr.success(msg, "نجاح");
            }
          });
        }
        
      }
      btn.click();
      // this.router.navigate(["/profile"]);
      //this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
      setTimeout(() => {
        this.btnRefresh.nativeElement.click()
      }, 3000);
    });
  }

  makeid() {
    var text = "";
    var possible = "0123456789";

    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    this.code = text;
  }
}
