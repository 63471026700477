import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.css']
})
export class SuccessPageComponent implements OnInit {
  msg = ''
  status = ''
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private _app: AppOperationService,) { }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => { 
      console.log(params.id) 
      let tran_ref = localStorage.getItem("paymentId")
      let type = localStorage.getItem("type")

      this._app.CheckPay({type: type, hyperpay_id: tran_ref}).subscribe(x=>{
        let items = x[appConstant.ITEMS] as any
        let status = x[appConstant.STATUS] as boolean
        if(status){
          this.updateUserWallet()
        }else{
          localStorage.removeItem("paymentId")
          localStorage.removeItem("value")
          localStorage.removeItem("type")
          this.msg = x[appConstant.MESSAGE]
          this.status = "خطأ"
        }
      })
    }
  );

  
    // let tran_ref = localStorage.getItem("paymentId")
    //   if(tran_ref){
    //     this._app.CheckPay({tran_ref: tran_ref}).subscribe(x=>{
    //       let status = x[appConstant.STATUS] as boolean
    //       if(status){
    //         this.updateUserWallet()
    //       }else{
    //         localStorage.removeItem("value")
    //         localStorage.removeItem("tran_ref")
    //         this.msg = x[appConstant.MESSAGE]
    //         this.status = "خطأ"
    //       }
    //     })
    //   }
  }

  updateUserWallet(){
    let obj = {
       wallet: localStorage.getItem("value")
    }
    this._app.updateUserWallet(obj).subscribe(x=>{
        let msg = x[appConstant.MESSAGE]
        if(x[appConstant.STATUS]){
          this.status = "نجاح"
          this.msg = msg
          localStorage.removeItem("value")
          localStorage.removeItem("type")
          localStorage.removeItem("paymentId")
          localStorage.removeItem("value")
          setTimeout(() => {
            this.router.navigate(["/profile", { outlets: { profile: [ 'wallet' ] }}]);
          }, 1000);
        }else{
          localStorage.removeItem("paymentId")
          localStorage.removeItem("value")
          localStorage.removeItem("tran_ref")
          localStorage.removeItem("type")
          this.msg = x[appConstant.MESSAGE]
          this.status = "خطأ"
        }
      })
  }
}
