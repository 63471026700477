import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  items = [];

  @Output() change: EventEmitter<any> = new EventEmitter();


  constructor() { }


  getItems() {
    var a = JSON.parse(localStorage.getItem('items')) as any[]
    if (a) {
      this.items = a
    }
    var cart = JSON.parse(localStorage.getItem('cart')) as any[]
    if (cart) {
      this.items = []
      this.items = cart
    }
    this.change.emit(this.items);
  }
}
