import { Injectable } from "@angular/core";

interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  // { name: 'script2', src: "assets/js/select2/select2.min.js" },

  // { name: "script1", src: "node_modules/jquery/dist/jquery.js" },
  // {
  //   name: "script3",
  //   src: "node_modules/owl.carousel/dist/owl.carousel.js"
  // },
  { name: "script4", src: "assets/js/wow.min.js" },
  {
    name: "script5",
    src: "assets/js/jquery.malihu.PageScroll2id.min.js"
  },
  { name: "script6", src: "assets/js/moment.min.js" },
  {
    name: "script7",
    src:
      "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
  },
  {
    name: "script8",
    src: "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
  },
  {
    name: "script9",
    src:
      "https://cdn.jsdelivr.net/gh/fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js"
  },
  { name: "script10", src: "assets/js/jquery.countdown.min.js" },
  {
    name: "script11",
    src: "assets/js/tempusdominus-bootstrap-4.min.js"
  },
  { name: "script12", src: "assets/js/script.js" }
];
declare var document: any;

@Injectable()
export class ServiceService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        name: script.name
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        if (this.scripts[name].name === "react-app") {
          script.type = "text/babel";
          if (script.readyState) {
            // IE
            script.onreadystatechange = () => {
              if (
                script.readyState === "loaded" ||
                script.readyState === "complete"
              ) {
                script.onreadystatechange = null;
                this.scripts[name].loaded = true;
                resolve({ script: name, loaded: true, status: "Loaded" });
              }
            };
          } else {
            // Others
            script.onload = () => {
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            };
          }
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("body")[0].appendChild(script);
        // document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      }
    });
  }
}
