import { Component, OnInit } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { DatePipe } from "@angular/common";
import { ServiceService } from "src/app/services/service.service";

@Component({
  selector: "app-profile-notification",
  templateUrl: "./profile-notification.component.html",
  styleUrls: ["./profile-notification.component.css"]
})
export class ProfileNotificationComponent implements OnInit {
  notifications = [];
  order;
  id;
  myId;
  constructor(
    private dynamicScriptLoader: ServiceService,
    public datepipe: DatePipe,
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.loadScripts();

    this._app.getUser().subscribe(item => {
      this.myId = item[appConstant.ITEMS]._id as any;
      this._app.getuserNotifications().subscribe(x => {
        this.notifications = x[appConstant.ITEMS] as any[];
      });
    });
  }

  loadScripts() {
    this.dynamicScriptLoader
      .load("script10", "script12")
      .then(data => {
        // Script Loaded Successfully
      })
      .catch(error => console.log(error));
  }

  getDate(date) {
    let _date = this.datepipe.transform(date, "MMM dd, yyyy hh:mm:ss");
  }

  openModal(btn, id) {
    this.id = null;
    this.id = id;
    this._app.getSingleOrder(this.id).subscribe(x => {
      this.order = x[appConstant.ITEMS] as any;
      btn.click();
    });
  }

  openModal2(btn, id) {
    this.id = null;
    this.id = id;
    this._app.getSingleOrder(this.id).subscribe(x => {
      this.order = x[appConstant.ITEMS] as any;
      btn.click();
    });
  }
}
