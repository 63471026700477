import { ServiceService } from "./services/service.service";
import { Component, HostListener, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { AppOperationService } from "./services/app-operation.service";
import { appConstant } from "./services/_constant/appConstant";
import { ViewportScroller } from "@angular/common";
// import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "";
  pageYoffset: any;
  whatsAppPhone: any;

  constructor(
    private dynamicScriptLoader: ServiceService,
    private _router: Router,
    private helper: AppOperationService,
    private scroll: ViewportScroller,
    private _app: AppOperationService
  ) {
    _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeMenu();
      }
    });

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }


  closeMenu() {
    document.getElementsByTagName("body")[0].classList.remove("menu-toggle");
    document.getElementsByTagName("body")[0].classList.remove("menu-toggle2");
    // document.getElementsByClassName("hamburger")[0].classList.remove("active");
    // document.getElementsByClassName("hamburger2")[0].classList.remove("active");
  }

  private loadScripts() {
    this.dynamicScriptLoader
      .load(
        // "script1",
        // "script3",
        "script4",
        "script5",
        "script6",
        "script7",
        "script8",
        "script9",
        "script10",
        "script11",
        "script12"
      )
      .then(data => {
        // Script Loaded Successfully
      })
      .catch(error => console.log(error));
  }

  ngOnInit() {
    this.loadScripts();
    this.checkTime();
    this.getWhatsAppLink();
  }

  getWhatsAppLink(): void{
    this._app.getSocialOptionData().subscribe(_users => {
      this.whatsAppPhone = _users[appConstant.ITEMS].filter(x => x.name == "whatsapp")[0].data;
      console.log(this.whatsAppPhone)
    });

  }

  checkTime() {
    if (localStorage.getItem("user") != null) {
      let expire = localStorage.getItem("expire");
      if (expire && new Date(expire) >= new Date()) {
        //return true;
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("expire");
        localStorage.removeItem("isAgree");
        setTimeout(() => {
          this._router.navigateByUrl("/home");
        }, 1000);
      }
    }
  }

  checkUser() {
    this.helper.getUser().subscribe(x => {
      if (!x[appConstant.STATUS]) {
        this._router.navigate(["/home"])
      }
    })
  }
  // this._router.events.subscribe((route) => {
  // 	if (route instanceof NavigationStart) {
  //     jQuery('.menu-collabse,.dropdown-menu').removeClass('show')
  // 	}
  // 	if (route instanceof NavigationEnd) {
  // 	}
  // });
}
