import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {
  
  id="62820416dace788e3c460c4c"
  content = {
    title:"",
    details:""
  }
  
  constructor(private _app: AppOperationService, private route: ActivatedRoute) {}
  
  ngOnInit() {
    this.getData()
  }

  getData(){
    this._app.getSingleStaticPagenData(this.id).subscribe(x => {
      this.content = x[appConstant.ITEMS] as any;
      let plainText2 = x[appConstant.ITEMS]['content'].replace(/&quot;/g, '"')
      this.content.title = x[appConstant.ITEMS]['title']
      this.content.details = plainText2
    });
  }

  updateStorage(){
    //localStorage.setItem("isInfo",'1')
  }
  save(btn){
    localStorage.setItem("isInfo",'1')
    btn.click()
  }
}
