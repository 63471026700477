import { BadgeService } from "./../../services/badge.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppOperationService } from "./../../services/app-operation.service";
import { Component, OnInit } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ViewChild, ElementRef } from "@angular/core";
import { ServiceService } from "src/app/services/service.service";

@Component({
  selector: "app-mobile-menu",
  templateUrl: "./mobile-menu.component.html",
  styleUrls: ["./mobile-menu.component.css"]
})
export class MobileMenuComponent implements OnInit {
  @ViewChild("btnRefresh") btnRefresh: ElementRef;
  
  isLogin = false;
  user;
  socialPages = [];
  constructor(
    private badgeService: BadgeService,
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private dynamicScriptLoader: ServiceService,
  ) {
    // this.loadScripts()
  }

  ngOnInit() {
    this._app.getSocialOptionData().subscribe(_users => {
      this.socialPages = _users[appConstant.ITEMS] as any[];
    });
    this.toggle();
    // this.loadScripts()
  }

  toggle() {
    if (this._app.checkLoginUser() == false) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
      this._app.getUser().subscribe(x => {
        this.user = x[appConstant.ITEMS] as any;
      });
    }
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("expire");
    localStorage.removeItem("isAgree");
    
    setTimeout(() => {
      this.toggle();
      this.btnRefresh.nativeElement.click()
    }, 1000);
  }

  private loadScripts() {
    this.dynamicScriptLoader
      .load(
        // "script1",
        // "script3",
        "script4",
        "script5",
        "script6",
        "script7",
        "script8",
        "script9",
        "script10",
        "script11",
        "script12"
      )
      .then(data => {
        // Script Loaded Successfully
      })
      .catch(error => console.log(error));
  }
}
