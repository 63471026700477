import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { appConstant } from "src/app/services/_constant/appConstant";
import { DatePipe } from "@angular/common";
import { ServiceService } from "src/app/services/service.service";

@Component({
  selector: "app-profile-opperation",
  templateUrl: "./profile-opperation.component.html",
  styleUrls: ["./profile-opperation.component.css"]
})
export class ProfileOpperationComponent implements OnInit {
  @ViewChild("btnn") open_info: ElementRef;

  opperations = [];
  order;
  id;
  myId;
  // createAt = "Dec 21, 2019 00:00:00";
  constructor(
    private dynamicScriptLoader: ServiceService,
    public datepipe: DatePipe,
    private _app: AppOperationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.loadScripts();

    this._app.getUser().subscribe(item => {
      this.myId = item[appConstant.ITEMS]._id as any;
      this._app.getuserOpperation().subscribe(x => {
        this.opperations = x[appConstant.ITEMS] as any[];

       setTimeout(() => {
          this.checkInfo()
        }, 2000);
      });
    });

  }

  checkInfo(){
    let isAgree = localStorage.getItem("isInfo")
    if(!isAgree){
     this.open_info.nativeElement.click();
    }
  }

  private loadScripts() {
    this.dynamicScriptLoader
      .load("script10", "script12")
      .then(data => {
        // Script Loaded Successfully
      })
      .catch(error => console.log(error));
  }

  getBckground(color) {
    switch (color) {
      case 2:
        return "#e7ffc9";
      case 3:
        return "#e7ffc9";
      case 4:
        return "#e7ffc9";
      case 5:
        return "#e7ffc9";
      case 6:
        return "#ffdddc";
      case 7:
        return "#ffdddc";
      default:
        return "#fff3dc";
    }
  }

  getColor(color) {
    switch (color) {
      case 2:
        return "#81bd60";
      case 3:
        return "#81bd60";
      case 4:
        return "#81bd60";
      case 5:
        return "#81bd60";
      case 6:
        return "#d46966";
      case 7:
        return "#d46966";
      default:
        return "#ffb628";
    }
  }

  getDate(date) {
    let _date = this.datepipe.transform(date, "MMM dd, yyyy hh:mm:ss");
  }

  openInfoModal(btn) {
    btn.click();
  }

  openModal(btn, id) {
    this.id = null;
    this.id = id;
    this._app.getSingleOrder(this.id).subscribe(x => {
      this.order = x[appConstant.ITEMS] as any;
      btn.click();
    });
  }

  checkStatus(item) {
    if (item.paymentBy == 1) {
      return item.from_user._id == this.myId;
    } else {
      return item.to_user._id == this.myId;
    }
  }

  checkEndStatus(item) {
    // if (item.paymentBy == 1) {
    return item.to_user._id == this.myId;
    // } else {
    //   return item.from_user._id == this.myId;
    // }
  }
}
