import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-code",
  templateUrl: "./code.component.html",
  styleUrls: ["./code.component.css"]
})
export class CodeComponent implements OnInit {
  @Input() order: any;
  @ViewChild("btnRefresh") btnRefresh: ElementRef;
  content = {
    checkCode: ""
  };

  constructor(
    private _app: AppOperationService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  ngOnInit() {}

  checkCodeFunc(btn) {
    this._app.checkOrderCode(this.content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }
      this.content = {
        checkCode: ""
      };
      btn.click();
      // this.router.navigate(["/profile"]);
      //this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
      setTimeout(() => {
        this.btnRefresh.nativeElement.click()
      }, 3000);
    });
  }
}
