import { Component, OnInit, Input } from '@angular/core';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rate-other',
  templateUrl: './rate-other.component.html',
  styleUrls: ['./rate-other.component.css']
})
export class RateOtherComponent implements OnInit {

  @Input() order: any
  rating = 1;
  content = {
    rate: 1,
    comment: ''
  }

  constructor(private _app: AppOperationService, private toastr: ToastrService, private route: ActivatedRoute, private spinner: NgxSpinnerService, private router: Router) {
  }
  ngOnInit() {
  }


  rate(btn) {
    this.content.rate = this.rating;
    this._app.postAddRateOther(this.content, this.order._id).subscribe(x => {
      let status = x['status'] as boolean
      let msg = x[appConstant.MESSAGE] as string
      if (status == false) {
        this.toastr.error(msg, 'خطأ')
      } else {
        this.toastr.success(msg, 'نجاح')
      }
      this.content = {
        rate: 1,
        comment: ''
      }
      this.rating = 1;
      btn.click()
      this.router.navigate(['/profile', { outlets: { profile: ['opperation'] } }])
    })
  }

  onClick(rating: number): void {
    this.rating = rating;
  }
}
