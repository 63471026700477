import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  details = {
    title: '',
    content: ''
  };
  id = ''
  wallets_arr = []
  wallet = 0.0;
  user;
  MyIp;
  hide_button = false;

  constructor(
    private _app: AppOperationService, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.getuser()
  }


  getUserWallet(id){
    this._app.getUserWallet(id).subscribe(x => {
      this.wallets_arr = x[appConstant.ITEMS] as any[];
    });

  }

  getuser() {
    this._app.getUser().subscribe(x => {
      this.wallet = x[appConstant.ITEMS]["wallet"] as any;
      this.user = x[appConstant.ITEMS];
      this.getUserWallet(this.user._id)
    });
  }
}

