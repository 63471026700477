import { AppOperationService } from 'src/app/services/app-operation.service';
import { AfterContentInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-apple',
  templateUrl: './apple.component.html',
  styleUrls: ['./apple.component.css']
})
export class AppleComponent implements OnInit , AfterContentInit{

  constructor(
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private route: ActivatedRoute, 
    ) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      let id = params['id'] == undefined ? null : params['id'];
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.src= "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+id;
       script.text = 
       `var wpwlOptions = {
           paymentTarget:"_top",
           applePay: {
             displayName: "Dhamin",
             total: { label: "Dhamin, INC." },
             merchantCapabilities: ["supports3DS"],
             supportedNetworks: ["mada", "masterCard", "visa"],
             supportedCountries: ["SA"]
           }
         }`  
      this._renderer2.appendChild(this._document.body, script);
    })
   
  }

  ngAfterContentInit(): void {
    this.route.params.subscribe((params: Params) => {
      let id = params['id'] == undefined ? null : params['id'];
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.src= "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+id;
       script.text = 
       `var wpwlOptions = {
           paymentTarget:"_top",
           applePay: {
             displayName: "Dhamin",
             total: { label: "Dhamin, INC." },
             merchantCapabilities: ["supports3DS"],
             supportedNetworks: ["mada", "masterCard", "visa"],
             supportedCountries: ["SA"]
           }
         }`  
      this._renderer2.appendChild(this._document.body, script);
    })
  }
}
