import { Component, OnInit } from '@angular/core';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-profile-rating',
  templateUrl: './profile-rating.component.html',
  styleUrls: ['./profile-rating.component.css']
})
export class ProfileRatingComponent implements OnInit {

  ratings = []
  rating = 0
  constructor(private _app: AppOperationService, private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this._app.getuserRatings().subscribe(x => {
      this.ratings = x[appConstant.ITEMS] as any[]
      this.rating =  x['rating'] as any
    })
  }

  counter(i: number) {
    return new Array(i);
  }
}