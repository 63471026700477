import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Directive({
  selector: '[appCustomDateFormat]'
})
export class CustomDateFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Check if the value is not empty
    if (value) {
      // Format the date to 'yyyy-MM-dd'
      const parts = value.split('/');
      if (parts.length === 3) {
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        this.el.nativeElement.value = formattedDate;
      }
    }
  }
}
