import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  details = {
    title: '',
    content: ''
  };
    id = "625d9db1f21155c11e76d8fd"
  isAgree = false;
  @ViewChild("btnRefresh") btnRefresh: ElementRef;

  constructor(private _app: AppOperationService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    let firstContract = JSON.parse(localStorage.getItem("firstContract"));
    if(firstContract)
    {
      setTimeout(() => {
        this.router.navigate(["/contract/"+firstContract.category_id]);
      }, 1000);
    }else{
      this._app.getSingleStaticPagenData(this.id).subscribe(x => {
        this.details = x[appConstant.ITEMS] as any;
          // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
          let plainText2 = x['content'].replace(/&quot;/g, '"')
          this.details.title = x['title']
          this.details.content = plainText2
      });
    }
  }

  changeStatus(val) {
    this.isAgree = val;
    localStorage.setItem("isAgree", String(this.isAgree));
  }

  goToProfile(){
    localStorage.setItem("isAgree", String(true));
    this.btnRefresh.nativeElement.click()
  }
}
