import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { appConstant } from "src/app/services/_constant/appConstant";
import { AppOperationService } from "src/app/services/app-operation.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-draw",
  templateUrl: "./draw.component.html",
  styleUrls: ["./draw.component.css"]
})
export class DrawComponent implements OnInit {
  @Input() order: any;
  @ViewChild("btnRefresh") btnRefresh: ElementRef;

  _accept = "";
  _reject = "";
  _update = "";
  isAgree = false;
  myId = ""
  constructor(
    private _app: AppOperationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.getUserInfo()
  }

  ngOnInit() {}

  getUserInfo(){
    this._app.getUser().subscribe(item => {
      this.myId = item[appConstant.ITEMS]._id as any;
    });
  }

  decline(btn) {
    let content = {
      status: 6
    };
    if (this.isAgree == false) {
      this.toastr.error("الرجاء الموافقة على الشروط والأحكام", "");
      return;
    }
    this._app.updateOrderByUser(content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }
      btn.click();
      // this.router.navigate(["/profile"]);
     // this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
     
     setTimeout(() => {
      this.btnRefresh.nativeElement.click()
    }, 3000);
  });
  }

  approve(btn, btn2) {
    if (this.isAgree == false) {
      this.toastr.error("الرجاء الموافقة على الشروط والأحكام", "");
      return;
    }

    // if (this.order.category_id._id == "5db872a009a9b47513be77e3" ) {
    //   btn.click();
    //   setTimeout(() => {
    //     btn2.click();
    //   }, 1000);
    // } else {

    // }

    let content = {
      status: 2
    };

    this._app.updateOrderByUser(content, this.order._id).subscribe(x => {
      let status = x["status"] as boolean;
      let msg = x[appConstant.MESSAGE] as string;
      if (status == false) {
        this.toastr.error(msg, "خطأ");
      } else {
        this.toastr.success(msg, "نجاح");
      }
      btn.click();
      // setTimeout(() => {
      //   btn2.click();
      // }, 1000);
      // this.router.navigate(["/profile"]);
      //this.router.navigate(["/profile",{ outlets: { profile: [ 'opperation' ] }}]);
      setTimeout(() => {
        if(this.order.to_user._id == this.myId && this.order.category_id.code == "Buys" && this.order.userType == 2 ){
          //open payment modal.
          this.openPayment(btn2)
        }
        else if(this.order.from_user._id == this.myId && this.order.category_id.code == "Buys" && this.order.userType == 1 ){
          //open payment modal.
          this.openPayment(btn2)
        }
        else if(this.order.to_user._id == this.myId && this.order.category_id.code == "Delivery"){
          //open payment modal.
          this.openPayment(btn2)
        }
        else if(this.order.to_user._id == this.myId && this.order.category_id.code == "Contract" && this.order.pyamentType == 2){
          //open payment modal.
          this.openPayment(btn2)
        }
        else if(this.order.from_user._id == this.myId && this.order.category_id.code == "Contract" && this.order.pyamentType == 1){
          //open payment modal.
          this.openPayment(btn2)
        }
        else{
            this.btnRefresh.nativeElement.click()
        }
      }, 2000);
    });
  }

  update(btn, id) {
    btn.click();
    this.router.navigate([
      `/contract/${this.order.category_id._id}/${this.order._id}`
    ]);
  }
  changeStatus(val) {
    this.isAgree = val;
  }


  openPayment(btn) {
    btn.click();
  }
}
