import { AppOperationService } from 'src/app/services/app-operation.service';
import { AfterContentInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-bankinfo',
  templateUrl: './bankinfo.component.html',
  styleUrls: ['./bankinfo.component.css']
})
export class BankinfoComponent implements OnInit, AfterContentInit {
  @ViewChild("btnRefresh") btnRefresh: ElementRef;
  @ViewChild("btnRefresh2") btnRefresh2: ElementRef;
  details = {
    title: '',
    content: ''
  };
  id = ''
  type="1"
  isApplePay = false
  appleId=""
  constructor(
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private _app: AppOperationService, 
    private route: ActivatedRoute, 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.spinner.show()
      this._app.getSingleStaticPagenData("6294f84a11dee5c6830cb472").subscribe(x => {
        this.details = x[appConstant.ITEMS] as any;
        // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
        let plainText2 = x['content'].replace(/&quot;/g, '"')
        this.details.title = x['title']
        this.details.content = plainText2
        this.spinner.hide()
      });

      let id = params['id'] == undefined ? null : params['id'];
      if(id != '1'){
        this.isApplePay = true
        this.type = '3'
        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.src= "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+id;
         script.text = 
         `var wpwlOptions = {
             paymentTarget:"_top",
             applePay: {
               displayName: "Dhamin",
               total: { label: "Dhamin, INC." },
               merchantCapabilities: ["supports3DS"],
               supportedNetworks: ["mada", "masterCard", "visa"],
               supportedCountries: ["SA"]
             }
           }`  
        this._renderer2.appendChild(this._document.body, script);
      }
    })

    // this.route.params.subscribe((params: Params) => {
      // this.id = params['id'] == undefined ? null : params['id'];
      this.spinner.show()
      this._app.getSingleStaticPagenData("6294f84a11dee5c6830cb472").subscribe(x => {
        this.details = x[appConstant.ITEMS] as any;
        // let plainText = x['content'].replace(/<\/?[^>]+(>|$)/g, "");
        let plainText2 = x['content'].replace(/&quot;/g, '"')
        this.details.title = x['title']
        this.details.content = plainText2
        this.spinner.hide()
      });
    // });
  }

  ngAfterContentInit(): void {
    this.route.params.subscribe((params: Params) => {
      let id = params['id'] == undefined ? null : params['id'];
      if(id != '1'){
        this.isApplePay = true
        this.type = '3'
        let script = this._renderer2.createElement('script');
        script.type = `text/javascript`;
        script.src= "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+id;
         script.text = 
         `var wpwlOptions = {
             paymentTarget:"_top",
             applePay: {
               displayName: "Dhamin",
               total: { label: "Dhamin, INC." },
               merchantCapabilities: ["supports3DS"],
               supportedNetworks: ["mada", "masterCard", "visa"],
               supportedCountries: ["SA"]
             }
           }`  
        this._renderer2.appendChild(this._document.body, script);
      }
    })
  }


  updateWallet(value) {
    if(value == '0'  || value == '' || value == undefined || value == null || Number(value) <= 0){
      this.toastr.error("الرجاء ادخل المبلغ","خطأ");
      return;
    }
    var payment = {
      conent:"شحن محفظة",
      cart_id: this.makeid(),
      ammount: value,
      type: this.type
    }
    this._app.postPayment(payment).subscribe(x=>{
      let msg = x[appConstant.MESSAGE];
      if(x[appConstant.STATUS]){
         let res  = x[appConstant.ITEMS] as any
         localStorage.setItem("type", this.type);
         localStorage.setItem("value", value);
         localStorage.setItem("paymentId", res.id);
         let script = this._renderer2.createElement('script');
         script.type = `text/javascript`;
         this.appleId = res.id
         script.src= "https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="+res.id;
         this.isApplePay = String(this.type) == '3' ? true : false
         console.log(this.type)
         if(String(this.type) == '3') {
          setTimeout(() => {this.btnRefresh.nativeElement.click()}, 500);
         }else{
          //setTimeout(() => {this.btnRefresh2.nativeElement.click()}, 500);
         }
         this._renderer2.appendChild(this._document.body, script);
         this.toastr.success(msg,"نجاح");
      }else{
         this.toastr.error(msg,"خطأ");
      }
    });
  }

  makeid() {
    var text = "";
    var possible = "0123456789";
    for (var i = 0; i < 6; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

}
