import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem("user") != null) {
      let expire = localStorage.getItem("expire");
      let isAgree = localStorage.getItem("isAgree");
      if(!isAgree){
        this.router.navigate(["/welcome"]);
        return false;
      }
      if (expire && new Date(expire) >= new Date()) {
				return true;
			} else {
        localStorage.removeItem("user");
        localStorage.removeItem("expire");
        localStorage.removeItem("isAgree");
        setTimeout(() => { this.router.navigateByUrl("/login") }, 1000);
			}
    }
    this.router.navigate(["/login"]);
  }
}
