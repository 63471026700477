import { Component, OnInit } from '@angular/core';
import { AppOperationService } from 'src/app/services/app-operation.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(private _app: AppOperationService, private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit() {

  }
}