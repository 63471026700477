import { AppOperationService } from 'src/app/services/app-operation.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConstant } from 'src/app/services/_constant/appConstant';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  @Input() isFromHome: Boolean = false;

  returnMsg = ''
  phone = ''
  _jawwal = ''
  contact = {
    name: '',
    jawal: '',
    email: '',
    message: ''
  }
  obj = []
  constructor(private _app: AppOperationService, private toastr: ToastrService, private spinner: NgxSpinnerService) { }
  ngOnInit() {
    
    this._app.getContactOptionData().subscribe(x => {
      this.obj = x[appConstant.ITEMS] as any[]
    })
  }


  Save(content) {
    this._app.addContact(this.contact).subscribe(x => {
      let status = x['status'] as Boolean
      this.returnMsg = x[appConstant.MESSAGE] as string
      if (status == false) {
        this.toastr.error(this.returnMsg, 'خطأ')
      } else {
        this.toastr.success(this.returnMsg, 'نجاح')
      }
      this.contact = {
        name: '',
        jawal: '',
        email: '',
        message: ''
      }
    })
    ///send Email to support of Liken Co.
  }
}
