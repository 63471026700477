import { ServiceService } from "./services/service.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TimeAgoPipe } from "time-ago-pipe";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/header/header.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { HomeComponent } from "./pages/home/home.component";
import { RouterModule } from "@angular/router";
import { ContactComponent } from "./pages/contact/contact.component";
import { StaticPageComponent } from "./pages/static-page/static-page.component";

import { NotfoundComponent } from "./pages/notfound/notfound.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FileUploadModule } from "ng2-file-upload";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";
import { OwlModule } from "ngx-owl-carousel";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogItemComponent } from "./shared/blog-item/blog-item.component";
import { BlogDetailsComponent } from "./pages/blog-details/blog-details.component";
import { LoginComponent } from "./pages/login/login.component";
import { ForgetPasswordComponent } from "./pages/forget-password/forget-password.component";
import { RegisterComponent } from "./pages/register/register.component";
import { MobileMenuComponent } from "./shared/mobile-menu/mobile-menu.component";
import { LoaderComponent } from "./shared/loader/loader.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { CategoryMenuComponent } from "./shared/category-menu/category-menu.component";
import { ProfileMenuComponent } from "./shared/profile-menu/profile-menu.component";
import { ProfileWalletComponent } from "./pages/profile-wallet/profile-wallet.component";
import { ProfileRatingComponent } from "./pages/profile-rating/profile-rating.component";
import { ProfileOpperationComponent } from "./pages/profile-opperation/profile-opperation.component";
import { ProfileNotificationComponent } from "./pages/profile-notification/profile-notification.component";
import { ProfileInfoComponent } from "./pages/profile-info/profile-info.component";
import { ContractComponent } from "./pages/contract/contract.component";
import { SearchComponent } from "./pages/search/search.component";
import { ChatModalComponent } from "./shared/chat-modal/chat-modal.component";
import { TransformModalComponent } from "./shared/transform-modal/transform-modal.component";
import { OperationModalComponent } from "./shared/operation-modal/operation-modal.component";
import { DrawComponent } from "./shared/draw/draw.component";
import { DisputeComponent } from "./shared/dispute/dispute.component";
import { EndComponent } from "./shared/end/end.component";
import { RateComponent } from "./shared/rate/rate.component";
import { AuthGuardService } from "./services/auth-guard.service";
import { DatePipe } from "@angular/common";
import { CheckoutResultComponent } from "./pages/checkout-result/checkout-result.component";
import { CodeComponent } from './shared/code/code.component';
import { RateOtherComponent } from './shared/rate-other/rate-other.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { InfoComponent } from './shared/info/info.component';
import { BankinfoComponent } from './pages/bankinfo/bankinfo.component';
import { TranferComponent } from "./pages/tranfer/tranfer.component";
import { RechargeComponent } from './pages/recharge/recharge.component';
import { HistoryComponent } from './pages/history/history.component';
import { AppleComponent } from './apple/apple.component';
import { EmailInputRestrictorDirective } from './shared/directives/email-input-restrictor.directive';
import { NumbersOnlyDirective } from './shared/directives/numbers-only.directive';
import { DateMaskDirective } from './shared/directives/date-mask.directive';
import { CustomDateFormatDirective } from './shared/directives/custom-date-format.directive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactComponent,
    StaticPageComponent,
    NotfoundComponent,
    BlogComponent,
    BlogItemComponent,
    BlogDetailsComponent,
    LoginComponent,
    ForgetPasswordComponent,
    RegisterComponent,
    MobileMenuComponent,
    LoaderComponent,
    ProfileComponent,
    CategoryMenuComponent,
    ProfileMenuComponent,
    ProfileWalletComponent,
    ProfileRatingComponent,
    ProfileOpperationComponent,
    ProfileNotificationComponent,
    ProfileInfoComponent,
    TimeAgoPipe,
    ContractComponent,
    SearchComponent,
    ChatModalComponent,
    TransformModalComponent,
    OperationModalComponent,
    DrawComponent,
    DisputeComponent,
    EndComponent,
    RateComponent,
    CheckoutResultComponent,
    CodeComponent,
    RateOtherComponent,
    SuccessPageComponent,
    WelcomeComponent,
    InfoComponent,
    BankinfoComponent,
    TranferComponent,
    RechargeComponent,
    HistoryComponent,
    AppleComponent,
    EmailInputRestrictorDirective,
    NumbersOnlyDirective,
    DateMaskDirective,
    CustomDateFormatDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    FileUploadModule,
    LazyLoadImageModule,
    NgxSpinnerModule,
    OwlModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBINaIuqvaFNbVQhkvVFrR3kjdWguiZ_pg"
    }),
    ToastrModule.forRoot({
      timeOut: 20000,
      positionClass: "toast-top-left",
      preventDuplicates: true
    }),
    RouterModule.forRoot(
      [
        {
          path: "home",
          component: HomeComponent
        },
        {
          path: "apple/:id",
          component: AppleComponent,
          // canActivate: [AuthGuardService]
        },
        {
          path: "404",
          component: NotfoundComponent
        },
        {
          path: "details/:id",
          component: BlogDetailsComponent
        },
        {
          path: "blog",
          component: BlogComponent
        },
        {
          path: "contact",
          component: ContactComponent
        },
        {
          path: "staticPage/:id",
          component: StaticPageComponent
        },
        {
          path: "welcome",
          component: WelcomeComponent,
          // canActivate: [AuthGuardService]
        },
        {
          path: "login",
          component: RegisterComponent
        },
        {
          path: "register",
          component: RegisterComponent
        },
        {
          path: "search",
          component: SearchComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "contract/:id/:cid",
          component: ContractComponent
          // canActivate: [AuthGuardService]
        },
        {
          path: "contract/:id",
          component: ContractComponent
          // canActivate: [AuthGuardService]
        },
        {
          path: "contract",
          component: ContractComponent
          // canActivate: [AuthGuardService]
        },
        {
          path: "reset",
          component: ForgetPasswordComponent
        },
        {
          path: "checkout_result",
          component: CheckoutResultComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "result",
          component: SuccessPageComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "charge/:id",
          component: BankinfoComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "transfer",
          component: TranferComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "history",
          component: HistoryComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "recharge",
          component: RechargeComponent,
          canActivate: [AuthGuardService]
        },
        {
          path: "profile",
          component: ProfileComponent,
          canActivate: [AuthGuardService],
          children: [
            {
              path: "info",
              component: ProfileInfoComponent,
              outlet: "profile",
              canActivate: [AuthGuardService]
            },
            {
              path: "wallet",
              component: ProfileWalletComponent,
              outlet: "profile",
              canActivate: [AuthGuardService]
            },
            {
              path: "rating",
              component: ProfileRatingComponent,
              outlet: "profile",
              canActivate: [AuthGuardService]
            },
            {
              path: "opperation",
              component: ProfileOpperationComponent,
              outlet: "profile",
              canActivate: [AuthGuardService]
            },
            {
              path: "notification",
              component: ProfileNotificationComponent,
              outlet: "profile",
              canActivate: [AuthGuardService]
            }
          ]
        },
        {
          path: "",
          redirectTo: "/home",
          pathMatch: "full"
        }
        // { path: '**', component: NotfoundComponent }
      ],
      { scrollPositionRestoration: "enabled", onSameUrlNavigation: "reload" }
    ),
  ],
  providers: [ServiceService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {}
